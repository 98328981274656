<template>
  <div class="form">
      <div class="readonly-badge" v-if="this.readOnly">
        <i class="fa-solid fa-lock-keyhole"></i>
        <h4>READ ONLY</h4>
      </div>
      <HeaderSection :resData="resData"/>
      <div class="outer-form">
        <div id="myProgress" v-if="(this.stepWizard === 0)">
          <div id="myBar" class="myBar"></div>
        </div>
        <div id="myProgress" v-else-if="(this.stepWizard === 1)">
          <div id="myBar-one" class="myBar"></div>
        </div>
        <div id="myProgress" v-else-if="(this.stepWizard === 2)">
          <div id="myBar-two" class="myBar"></div>
        </div>
        <div id="myProgress" v-else-if="(this.stepWizard === 3)">
          <div id="myBar-three" class="myBar"></div>
        </div>
        <div id="myProgress" v-else-if="(this.stepWizard === 4)">
          <div id="myBar-four" class="myBar"></div>
        </div>
        <template v-if="this.routes.WelcomePage"><WelcomePage :routes="this.routes" :resData="resData" :stepWizard="stepWizard"/></template>
          <template v-for="section in this.resData.form" :key="section.id">
            <template v-if="(section.index === siteIndex && this.routes.FormPages)">
              <FormSection :section="section" :routes="this.routes" :stepWizard="stepWizard" v-model:siteIndex="siteIndex" @changeSiteIndex="changeSiteIndex" :resData="resData" :readOnly="this.readOnly"/>
            </template>
          </template>
        <template v-if="this.routes.ThankYouPage"><ThankYouPage :routes="this.routes"/></template>
      </div>
      <FooterSection/>
  </div>
</template>

<script>
import store from "./../store.js"

import HeaderSection from "./HeaderSection.vue"
import FooterSection from "./FooterSection.vue"
import WelcomePage from "./WelcomePage.vue"
import FormSection from "./FormSection.vue"

import ThankYouPage from "./ThankYouPage.vue"

export default {
  name: 'MainFrom',
  components: {
    HeaderSection,
    FooterSection,
    WelcomePage,
    FormSection,
    ThankYouPage,
  },
  props: ["resData", "readOnly"],
  data() {
    return {
      routes: store.routes,
      header: store.getResponse,
      siteIndex: 0,
      stepWizard: 0,
      isReadOnly: store.readOnly
    }
  },
  methods: {
    changeSiteIndex(e) {
      if(e === 'increase') {
        this.siteIndex++
      } else if (e === 'decrease') {
        this.siteIndex--
        if(this.siteIndex < 0) {
          this.siteIndex = 0
        }
      }
    }
  }
}
</script>

<style>
.readonly-badge {
  position: fixed;
  top: 0;
  left: 10%;
  background-color: #BA2763;
  color: #fff;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  padding: 5px 10px;
  z-index: 100;
}
.readonly-badge h4 {
  margin: 0;
  margin-left: 1rem;
}
.readonly-badge i {
  font-size: 20px;
}
.form-section {
  margin: auto;
  width: 100%;
  overflow: hidden;
}
.inner-form {
  margin-top: 2vh;
}
.assessment {
  display: flex;
  justify-content: space-between;
  width: 40vw;
  align-items: center;
  border-top: 1px solid rgba(0, 0, 0, .1);
}
.field label {
  line-height: 1px;
  display: flex;
  justify-content: right;
  align-items: center;
}
.outer-form {
  background: #fff;
  width: 80%;
  margin: auto;
  position: relative;
}
.form-wrapper {
  margin-top: 3rem;
}
.form-wrapper h1 {
  color: #fff;
}

/* Buttons */
.add-btn, .remove-btn {
  border-radius: 50%;
  width: 30px;
  height: 30px;
  font-size: 14px;
  font-weight: 600;
  border: none;
  color: #fff;
}
.add-btn {
  background-color: #00a650;
  margin-left: 5px;
}
.add-btn:hover {
  background: #333249;
}
.remove-btn:hover {
  background: #00a650;
}
.remove-btn {
  background-color: gray;
  margin-left: 5px;
}
.add-btn:hover, .remove-btn:hover {
  cursor: pointer;
}
.bottom-area {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: space-between;
  margin-top: 4rem;
  background: #e5e5e5;
}
.bottom-area p {
  padding-left: 10px;
}
.bottom-area p {
  font-size: 20px;
}
.btn-area {
  display: flex;
  justify-content: space-between;
}
.submit-btn, .back-btn, .back-btn-mobile {
  padding: 10px;
  font-size: 14px;
  text-transform: uppercase;
  color: #fff;
  padding: 10px 30px;
}
.submit-btn, .back-btn, .back-btn-mobile {
  border: 1px solid #00a650;
  background: #00a650;
}
.danger-bg {
  border: 1px solid #BA2763;
  background: #BA2763;
}
.deactivated {
  border: 1px solid gray;
  background: gray;
}
.deactivated:hover {
  cursor: not-allowed;
}
.cancel-btn {
  border: 1px solid #8482b3;
  background: #8482b3;
}
.submit-btn:hover, .back-btn:hover {
  cursor: pointer;
  color: #fff;
  border: 1px solid #333249;
  background: #333249;
}

.back-btn-mobile {
  display: none;
}

@media only screen and (max-width: 1000px) and (min-width: 700px) {
  .inner-form {
  margin-top: 2vh;
}
}

@media only screen and (max-width: 700px) {
  #app {
    width: 100vw;
    margin: auto;
  }
  .outer-form {
    width: 100%;
    margin: 0;
  }
  .form-section {
    width: 100%;
  }
  .add-btn {
    left: 20px;
    position: relative;
  }
  .remove-btn {
    position: relative;
    left: 20px;
  }
  .bottom-area {
    display: block;
    padding-top: 2px;
  }
  .bottom-area p {
    font-size: 14px;
  }
  .back-btn-mobile {
    display: block;
  }
  .back-btn {
    display: none;
  }
}

#myProgress {
  width: 100%;
  top: 0;
  position: absolute;
}

.myBar {
  height: 10px;
  background-color: #00a650;
}

#myBar {
  width: 0%;
  height: 10px;
  background-color: #00a650;
}
#myBar-one {
  width: 25%;
}
#myBar-two {
  width: 50%;
}
#myBar-three {
  width: 75%;
}
#myBar-four {
  width: 100%;
}
</style>