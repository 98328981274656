//store

export default {
    userInput: [],
    userURL: '',
    pageTitle: '',
    userId: '',
    userToken: '',
    routes: {
        WelcomePage: true,
        PerformanceSection: false,
        CompetenceSection: false,
        AgreementSection: false,
        ThankYouPage: false,
        index: 0
    },
    inputFields: []
}