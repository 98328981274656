<template>
  <form class="inner-form" id="competence" @submit.prevent="formOnSubmit(this.section)">
    <div class="form-section">
      {{ this.readOnly }}
      <h2>{{ section.title }}</h2>
      <p>{{ section.description }}</p>
      <div v-for="field in this.section.fields" :key="field.index" class="fields">
        <template v-if="(field.type === 'range')">
          <div class="subfield-desc">
            <h4>{{ field.title }} <i class="fa-duotone fa-asterisk asterisk"></i></h4>
            <p>{{ field.description }}</p>
          </div>
          <div class="field-range-inner">
            <div v-for="c in this.fieldRange" :key="c.index" class="outer-range-input">
              <input v-if="this.readOnly === false" type="radio" :name="field.id" :id="(c.id + '-' + field.id)" :value="c.id" v-model="this.input[section.id][field.id]" :checked="c.id == this.input[section.id][field.id]" required>
              <input v-else type="radio" :name="field.id" :id="(c.id + '-' + field.id)" :value="c.id" v-model="this.formData[field.id]" :checked="c.id == this.formData[field.id]">
              <div class="range-input" :class="this.isReadOnly ? 'range-input-readonly' : ''" :id="('wrapper-' + c.tag)">
                <label :for="(c.id + '-' + field.id)">
                  <i :class="c.icon" :id="c.tag" class="icons"></i>
                  <p>{{ c.label }}</p>
                </label>
              </div>
            </div>
          </div>
        </template>
        <template v-else-if="(field.type === 'list')">
          <div class="subfield-list">
            <div class="field-optional-task">
              <h4>{{ field.title }}</h4>
              <button class="add-btn" type="button" @click="addOption"><i class="fa-regular fa-plus"></i></button>
            </div>
            <p>{{ field.description }}</p>
            <div v-for="o in this.optionalFields" :key="o.index" class="fields">
              <div class="field-wrapper">
                <div class="field-optional-task">
                  <h4>{{ field.form[0].title }} <i class="fa-duotone fa-asterisk asterisk"></i></h4>
                  <button class="remove-btn" type="button" @click="removeOption(optionalFieldsIndex[index])"><i class="fa-solid fa-minus"></i></button>
                </div>
                <div class="textarea">
                  <input type="textarea" v-model="this.optionalTaskName[field.id + '-' + o]" required>
                </div>
              </div>
              <div class="field-range-inner">
                <div v-for="c in this.fieldRange" :key="c.index" class="outer-range-input">
                  <input type="radio" :name="(field.id + '-' + o + c.id)" :id="(field.id + '-' + o + c.id)" :value="c.id" v-model="this.optionalTaskVal[field.id + '-' + o + '-' + c.id]">
                  <div class="range-input" :id="('wrapper-' + c.tag)">
                    <label :for="(field.id + '-' + o + c.id)">
                      <i :class="c.icon" :id="c.tag" class="icons"></i>
                      <p>{{ c.label }}</p>
                    </label>
                    </div>
                </div>
              </div>
            </div>
          </div>
        </template>
        <template v-else-if="(field.type === 'textbox')">
          <div class="subfield-note">
            <h4>{{ field.title }}</h4>
            <p>{{ field.description }}</p>
            <textarea v-if="this.isReadOnly === false" :name="field.id" :id="field.id" cols="30" rows="10" v-model="input[section.id][field.id]"></textarea>
            <textarea v-else :name="field.id" :id="field.id" cols="30" rows="10" v-model="this.formData[field.id]" readonly></textarea>
          </div>
        </template>
        <template v-if="(field.type === 'compare')">
          <div style="width: 100%;">
            <div class="wrapper-subfield-desc">
              <div class="subfield-desc">
                <h4>{{ field.title }} <i class="fa-duotone fa-asterisk asterisk"></i></h4>
                <p>{{ field.description }}</p>
              </div>
              <div class="field-range-inner">
                <div v-for="c in this.fieldRange" :key="c.index" class="outer-range-input">
                  <p class="worker" id="assessor" v-if="(c.id === field.data[0])">Vorgesetzer</p>
                  <p class="worker" id="coworker" v-if="(c.id === field.data[1])">Mitarbeiter</p>
                  <input type="radio" :name="field.id" :checked="(c.id === field.data[0]) || (c.id === field.data[1])" :value="c.id">
                  <div class="range-input range-input-readonly" :id="('wrapper-' + c.tag)" :class="[c.id === field.data[0] ? ('wrapper-' + c.tag) : '', c.id === field.data[1] ? ('wrapper-' + c.tag) : '']">
                    <label :class="[c.id === field.data[0] ? 'assessor-bg' : '', c.id === field.data[1] ? 'worker-bg' : '']">
                      <i :class="c.icon" :id="c.tag" class="icons"></i>
                      <p>{{ c.label }}</p>
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div style="width: 100%;" class="editionalTextarea">
              <textarea v-if="this.isReadOnly === false" :name="field.id" :id="field.id" cols="30" rows="10" v-model="input[section.id][field.id + '-annotation']" required></textarea>
              <textarea v-else :name="field.id" :id="field.id" cols="30" rows="10" v-model="this.formData[field.id + '-annotation']" readonly></textarea>
              <div class="warning-area" v-if="((field.data[0] - field.data[1]) == 0)">
                <p>Keine Abweichung! </p>
                <i id="check" class="fa-solid fa-check"></i>
              </div>
              <div class="warning-area" v-if="((field.data[0] - field.data[1]) == 1 || (field.data[0] - field.data[1]) == -1)">
                <p>Leichte Abweichung! </p>
                <i id="light-warning" class="fa-duotone fa-triangle-exclamation"></i>
              </div>
              <div class="warning-area" v-if="((field.data[0] - field.data[1]) == 2 || (field.data[0] - field.data[1]) == -2)">
                <p>Hohe Abweichung! </p>
                <i id="warning" class="fa-duotone fa-siren-on"></i>
              </div>
              <div class="warning-area" v-if="((field.data[0] - field.data[1]) == 3 || (field.data[0] - field.data[1]) == -3)">
                <p>Extreme Abweichung! </p>
                <i id="danger" class="fa-solid fa-circle-radiation"></i>
              </div>
            </div>
          </div>
        </template>
      </div>
      <div class="bottom-area">
        <div class="btn-area">
          <button class="back-btn" type="button" @click="getBack"><i class="fa-light fa-chevron-left"></i> Zurück</button>
        </div>
        <p v-if="section.id === 'performance'">Nächster Schritt: <b>Kompetenz- und Gesamteinschätzung</b></p>
        <p v-else-if="section.id === 'competence'">Nächster Schritt: <b>Gesamteinschätzung</b></p>
        <p v-else-if="section.id === 'summary'">Nächster Schritt: <b>Formular absenden</b></p>
        <div class="btn-area">
          <button class="back-btn-mobile" type="button" @click="getBack"><i class="fa-light fa-chevron-left"></i> Zurück</button>
          <button v-if="this.section.index < this.siteLength - 1" class="submit-btn" type="submit">Weiter <i class="fa-light fa-chevron-right"></i></button>
          <button v-else-if="this.section.index === this.siteLength - 1" class="submit-btn danger-bg" :class="this.isReadOnly ? 'deactivated' : ''" type="submit">Abschließen <i class="fa-light fa-chevron-right"></i></button>
        </div>
      </div>
    </div>

    <div class="model" v-if="this.toggleModel && this.isReadOnly === false">
      <div class="model-wrapper">
        <h3>Formular wirklich absenden?</h3>
        <p style="padding: 0 10px;">Nach dem versenden des Formulars, ist eine spätere Bearbeitung nichtmehr möglich.</p>
        <div class="btn-area">
          <button class="submit-btn cancel-btn" type="button" @click="closeModel">abbrechen</button>
          <button class="submit-btn" type="submit" @click="sendForm">absenden</button>
        </div>
      </div>
    </div>
  </form>
</template>
  
<script>
  import store from './../store.js'
  import range from "../json/range.json"
  
  export default {
    name: 'MainInfos',
    props: ["section", "routes", "stepWizard", "siteIndex", "readOnly", "resData"],
    data() {
      return {
        input: store.inputFields,
        inputText: {},
        optionalTaskName: [],
        optionalTaskVal: [],
        fieldRange: range,
        currentStep: this.step,
        route: this.routes,
        optionalFields: [],
        optionalFieldsIndex: 10,
        steps: this.stepWizard,
        toggleModel: false,
        site: this.siteIndex,
        siteLength: store.formLength,
        isReadOnly: this.readOnly,
        formData: this.resData.formData
      }
    },
    methods: {
      formOnSubmit(section) {
        let optionalName = []
        let optionalVal = []

        for (const key in this.optionalTaskName) {
          const element = this.optionalTaskName[key];
          optionalName.push(element)
        }

        for (const key in this.optionalTaskVal) {
          const element = this.optionalTaskVal[key];
          optionalVal.push(element)
        }
        this.input[section.id]["tasks"] = []
        for (let i = 0; i < optionalName.length; i++) {
          this.input[section.id]["tasks"].push({
            "task": optionalName[i],
            "eval": optionalVal[i]
          })
        }
        //console.log(this.input[section.id]["tasks"].length)
        if(this.input[section.id]["tasks"].length === 0) {
          delete this.input[section.id]["tasks"]
        } 
        if(this.section.index < store.formLength - 1) {
            store.userInput = this.input
            this.steps++
            this.$emit('changeSiteIndex', 'increase')
        } else if(this.section.index === store.formLength - 1) {
            store.userInput = this.input
            this.steps++
            this.toggleModel = true
        } 
        window.scrollTo(0,0);
      },
      getBack() {
        if(this.section.index == 0) {
          store.routes.WelcomePage = true
          store.routes.FormPages = false
          this.$emit('changeSiteIndex', 'decrease')
        } else if(this.section.index <= store.formLength - 1 && this.section.index > 0) {
          this.$emit('changeSiteIndex', 'decrease')
          this.steps--
        }
      },
      addOption() {
        this.optionalFields.push(this.optionalFieldsIndex)
        this.optionalFieldsIndex++
      },
      removeOption(payload) {
        this.optionalFields.pop(payload)
        this.optionalFieldsIndex--
        this.optionalTaskVal.pop()
        this.optionalTaskName.pop()
      },
      closeModel() {
        this.toggleModel = false
      },
      sendForm() {
        let rawData = {}
        for (const key in store.userInput) {
          for (const index in store.userInput[key]) {
            const element = store.userInput[key][index];
            rawData[index] = element
          }
        }
        let userData = {
          "id": store.userId,
          "token": store.userToken
        }

        const requestOptions = {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(rawData),
          data: userData
        }

        fetch(store.userURL, requestOptions)
        .then((response) => {
          if(response.status === 200) {
            this.route.AgreementSection = false
            this.route.ThankYouPage = true
            this.steps++
            store.userInput = []
            store.userURL = ''
            this.$emit('changeSiteIndex', 'increase')
          } else if(response.status === 405) {
            this.steps++
            alert("Das Formular wurde bereits versendet. Eine erneute Bearbeitung ist nicht möglich!")
          }
        })
        .catch((e) => {
          console.error(e)
          alert("Beim Versand deiner Daten ist ein Fehler aufgetreten. Bitte versuche es später nochmal oder kontaktiere einen Admin.")
        })
      }
    }
  }
</script>
  
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .form-section p {
    text-align: left;
    padding-left: 20px;
  }
  #competence-field {
    margin: 0;
  }
  .wrapper-subfield-desc {
    display: flex;
  }
  .subfield-list, .subfield-note {
    width: 100%;
    text-align: left;
    position: relative;
  }
  .subfield-note {
    padding-left: 20px;
  }
  .subfield-list textarea, .subfield-note textarea {
    width: 90%;
  }
  .subfield-desc {
    text-align: left;
    padding: 0 20px;
    max-width: 40%;
  }
  .textarea {
    display: flex;
    align-items: center;
    margin-top: 0;
    text-align: left;
    padding: 0 20px;
    max-width: 40%;
    width: 40%;
  }
  textarea {
    border: 1px solid rgba(0, 0, 0, 1);
    border-radius: 5px;
  }
  .subfield-desc p {
    font-size: 13px;
    height: 50%;
    margin: auto;
    padding-left: 0;
    padding-bottom: 5px;
  }
  .subfield-desc input {
    font-size: 13px;
  }
  .fields {
    display: flex;
    border-top: 1px solid rgba(0, 0, 0, .2);
    justify-content: space-between;
  }
  .fields .textarea input {
    padding: 5px;
  }
  .field-wrapper {
    width: 40%;
  }
  .field-optional-task {
    display: flex;
    justify-content: left;
    align-items: center;
    position: relative;
    padding-left: 20px;
  }
  .outer-range-input {
    width: 25%;
    height: auto;
    position: relative;
  }
  .outer-range-input input {
    opacity: 0;
    bottom: 0;
    position: absolute;
    z-index: -1;
  }
  .field-range-inner {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 60%;
    position: relative;
    background: #F7F7FA;
  }
  .range-input {
    width: 100%;
    min-height: 6.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    /* border: 1px solid #F7F7FA; */
    position: relative;
    padding: 5px 0 15px 0;
    text-align: center;
  }
  .range-input-readonly label {
    cursor: not-allowed;
  }
  .range-input label:hover {
    cursor: pointer;
  }
  .range-input label i {
    margin-top: 5px;
    font-size: 3rem;
  }
  .range-input p {
    margin: auto;
    font-size: 10px;
    max-width: 90%;
    padding-top: 10px;
    padding-left: 0;
    text-align: center;
  }

  [type="radio"]:checked + #wrapper-confused, .wrapper-confused {
    background-color: #C15942;
    color: #fff;
  }
  [type="radio"]:checked + #wrapper-expressionless, .wrapper-expressionless {
    background-color: #F2C930;
    color: #fff;
  }
  [type="radio"]:checked + #wrapper-relaxed, .wrapper-relaxed {
    background-color: #0F88B7;
    color: #fff;
  }
  [type="radio"]:checked + #wrapper-laugh-beam, .wrapper-laugh-beam {
    background-color: #00A650;
    color: #fff;
  }
  [type="radio"]:checked + .range-input .icons, .assessor-bg, .worker-bg {
    /* filter: invert(1); */
    filter: brightness(2.5);
    /* filter: brightness(1.1) contrast(1.3); */
  }
  .subfield-desc {
    width: 40%;
  }

  p.worker {
    width: 100%;
    padding: 2px 0;
    margin: 0;
    position: absolute;
    z-index: 10;
    font-size: 12px;
    text-align: center;
    color: #F7F7FA;
  }
  p.worker::after {
    content:'';
    position: absolute;
    top: 80%;
    left: 0;
    right: 0;
    margin: 0 auto;
    width: 0;
    height: 0;
  }
  #assessor {
    background-color: #343349;
    color: #fff;
    text-align: center;
    top: -1.2rem;
  }
  #assessor::after {
    border-top: 12px solid #343349;
    border-left: 15px solid transparent;
    border-right: 15px solid transparent;
  }
  #coworker {
    background-color: #808080;
    bottom: -1rem;
  }
  #coworker::after {
    top: -25%;
    border-bottom: 10px solid #808080;
    border-left: 15px solid transparent;
    border-right: 15px solid transparent;
  }
  .editionalTextarea textarea {
    width: 95%;
    margin-top: 2.5rem;
  }
  .editionalTextarea .warning-area p {
    text-align: right;
    padding-right: 20px;
    margin-bottom: 0;
    margin-top: 0;
    font-size: 20px;
  }
  .editionalTextarea .warning-area i {
    font-size: 40px;
  }

  .warning-area {
    display: flex;
    align-items: center;
    justify-content: right;
    margin-bottom: 1rem;
    margin-right: 1rem;
  }
  #check {
    color: green;
  }
  #light-warning {
    color: #EFB33C;
    padding: 5px;
    border-radius: 50%;
  }
  #warning {
    color: #CC6E19;
  }
  #danger {
    color: #BA2763;
  }

  /* Range Smileys */
  #confused {
    color: #C15942;
  }
  #expressionless {
    color: #F2C930;
  }
  #relaxed {
    color: #0F88B7;
  }
  #laugh-beam {
    color: #00A650;
  }
  .asterisk {
    color: #FF5733;
  }

  .deactivated:hover {
    cursor: not-allowed;
  }

  .model {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 100;
    background: rgba(0, 0, 0, .8);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .model-wrapper {
    background: #fff;
    padding-top: 20px;
  }
  .model-wrapper h3 {
    margin: 0;
    padding: 0 15px 10px  15px;
  }

@media only screen and (max-width: 1000px) and (min-width: 700px) {
  .form-section {
    width: 100%;
  }
  .form-section h3 {
    font-size: 1.4rem;
  }
  .form-section p {
    font-size: 13px;
    padding-left: 20px;
    padding-right: 20px;
  }
  .wrapper-subfield-desc, .fields {
    display: block;
  }
  .field-wrapper {
    display: flex;
    justify-content: left;
    align-items: center;
    padding-bottom: 5px;
  }
  .field-optional-task {
    display: block;
  }
  .subfield-desc, .field-range-inner, .wrapper-subfield-desc .subfield-desc {
    width: 100%;
  }
  .subfield-desc, .textarea {
    text-align: left;
  }
  .subfield-desc h4, .subfield-list h4 {
    width: auto;
  }
  .subfield-desc p {
    padding-left: 0px;
    padding-right: 0px;
    margin-bottom: 10px;
    width: 70vw;
  }
  p.worker {
    text-align: center;
    width: 100%;
    padding: 5px 0;
    height: 10px;
    margin: 0;
    position: absolute;
    z-index: 10;
    font-size: 12px;
  }
  #coworker {
    bottom: -20px;
  }
  #assessor {
    top: -20px;
  }
}

@media only screen and (max-width: 700px) {
  .fields {
    display: block;
  }
  .fields .textarea {
    margin: auto;
    width: 60%;
    padding-top: 10px;
  }
  .form-section h3 {
    font-size: 100%;
  }
  .wrapper-subfield-desc, .fields {
    display: block;
  }
  .subfield-desc, .field-range-inner {
    width: 100%;
    max-width: 100%;
    padding: 0 0;
  }
  .textarea {
    text-align: center;
  }
  .subfield-list {
    padding-left: 0;
  }
  .subfield-note {
    padding-left: 20px;
    padding-right: 20px;
    width: auto;
  }
  .subfield-list h4, .subfield-desc h4 {
    padding-left: 20px;
  }
  .subfield-list textarea {
    margin-left: 10px;
    width: 85%;
  }
  .subfield-note textarea {
    width: 100%;
  }
  .field-range-inner {
     margin-top: 1rem;
  }
  .field-wrapper {
    width: 100%;
  }
  .field-optional-task {
    padding-left: 0;
    display: block;
  }
  .range-input {
    height: 3rem;
    min-height: 3rem;
  }
  .range-input label i {
    font-size: 2rem;
  }
  .range-input p {
    display: none;
  }
  .bottom-area {
    display: block;
  }
  p.worker {
    padding: 5px 0;
  }
  #coworker {
    bottom: -1.5rem;
  }
  #assessor {
    top: -2.5rem;
  }
}
</style>