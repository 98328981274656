<template>
  <footer>
      <section class="top-footer-section">
          <div class="top-footer-inner-left">
              <img src="./../assets/we-connect-white.svg" alt="we connect">
          </div>
          <div class="top-footer-inner-right">
              <h4>Copyright &copy; 2022 CMS-IT-Consulting GmbH</h4>
          </div>
      </section>
  </footer>
</template>

<script>

export default {
name: "FooterSection"
}
</script>

<style>
footer {
  margin: 1rem 10% 2rem 10%;
  color: #fff;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: hidden;
  min-height: 10vh;
}
.top-footer-section {
  display: flex;
  justify-content: space-between;
}
.top-footer-inner-left {
  display: flex;
  align-items: center;
}
.top-footer-inner-left img{
  width: 160px;
}

@media only screen and (max-width: 960px) and (min-width: 700px) {
  .top-footer-section {
    display: unset;
    text-align: center;
  }
  .top-footer-inner-left {
    justify-content: center;
  }
  
  .top-footer-inner-right h4 {
    font-size: 12px;
  }
}

@media only screen and (max-width: 700px) {
  .top-footer-section {
    display: unset;
    text-align: center;
  }
  .top-footer-inner-left {
    justify-content: center;
  }
  .top-footer-inner-left img {
    width: 130px;
  }
  .top-footer-inner-right h4 {
    font-size: 12px;
  }
}
</style>